import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import TimelineDot from '@material-ui/lab/TimelineDot';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import ImagesTourimac from '../imagesSettings/Tour/imagesTourimac';
import { useProductStyles } from './styles';
import CalculatorButton from '../Button/calculatorButton';

const ImacPage = () => {
  const { t } = useTranslation();
  const classes = useProductStyles();

  return (
    <>
      <Box mt={6} mb={6} className="masthead244 mastheadlightblue">
        <Grid container spacing={0}>
          <Grid item lg={12} md={12} xs={12}>
            <Box mt={6} mb={5}>
              <Typography className={classes.timelineheadingContentTour}>{t('trans.iMacHead')}</Typography>
            </Box>
          </Grid>

          <Grid item lg={6} md={8} xs={12}>
            <Box className={classes.imagesBoxImac}>
              <ImagesTourimac />
            </Box>
          </Grid>

          <Grid item lg={6} md={8} xs={12}>
            <Box ml={3}>
              <Typography className={classes.timeLineContentImac}>
                <TimelineDot className={classes.timelineDotImac} />
                {t('trans.iMacNumer10')}
              </Typography>
              <Box mt={2}>
                <Typography className={classes.timeLineContentImac}>
                  <TimelineDot className={classes.timelineDotImac} />
                  {t('trans.iMacNumer20')}
                </Typography>
              </Box>

              <Box mt={2}>
                <Typography className={classes.timeLineContentImac}>
                  <TimelineDot className={classes.timelineDotImac} />
                  {t('trans.iMacNumer30')}
                </Typography>
              </Box>

              <Box mt={2}>
                <Typography className={classes.timeLineContentImac}>
                  <TimelineDot className={classes.timelineDotImac} />
                  {t('trans.iMacNumer40')}
                </Typography>
              </Box>

              <Box mt={2}>
                <Typography className={classes.timeLineContentImac}>
                  <TimelineDot className={classes.timelineDotImac} />
                  {t('trans.iMacNumer50')}
                </Typography>
              </Box>

              <Box mt={2}>
                <Typography className={classes.timeLineContentImac}>
                  <TimelineDot className={classes.timelineDotImac} />
                  {t('trans.iMacNumer60')}
                </Typography>
              </Box>
              <Box mt={2}>
                <Typography className={classes.timeLineContentImac}>
                  <TimelineDot className={classes.timelineDotImac} />
                  {t('trans.iMacNumer70')}
                </Typography>
              </Box>
              <Box mt={2}>
                <Typography className={classes.timeLineContentImac}>
                  <TimelineDot className={classes.timelineDotImac} />
                  {t('trans.iMacNumer80')}
                </Typography>
              </Box>
              <Box mt={2}>
                <Typography className={classes.timeLineContentImac}>
                  <TimelineDot className={classes.timelineDotImac} />
                  {t('trans.iMacNumer85')}
                </Typography>
              </Box>
              <Box mt={2}>
                <Typography className={classes.timeLineContentImac}>
                  <TimelineDot className={classes.timelineDotImac} />
                  {t('trans.iMacNumer90')}
                </Typography>
              </Box>
              <Box mt={5} mb={3}>
                <Typography className={classes.headheadingContentTour4}>{t('trans.laptopContentButton')}</Typography>
                <CalculatorButton />
              </Box>
            </Box>
          </Grid>

          <Grid item lg={12} md={12} xs={12}>
            <Box mt={5} className={classes.boxContentImac}>
              <Typography className={classes.contentBoxImac}>{t('trans.iMacFooterOne')}</Typography>
            </Box>
            <Box mt={5} className={classes.boxContentImac}>
              <Typography className={classes.contentBoxImac}>{t('trans.iMacFooterTwo')}</Typography>
            </Box>
            <Box mt={0} className={classes.boxContentImac}>
              <Typography className={classes.contentBoxImac}>{t('trans.iMacFooterThree')}</Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default ImacPage;

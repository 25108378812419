import { makeStyles } from '@material-ui/core/styles';
import { COLOR_WHITE } from '../../theme/constants';

export const useProductStyles = makeStyles(() => ({
  headheadingSite: {
    color: '#080c2d',
    fontSize: '2.8rem',
    fontWeight: 900,
    textAlign: 'center',
    letterSpacing: '4px',
  },
  headheadingContent: {
    color: '#080c2d',
    fontSize: '1.4rem',
    fontWeight: 400,
    textAlign: 'center',
    letterSpacing: '0.5px',
  },

  productHeader: {
    display: 'flex',
    fontSize: '2.2em',
    fontWeight: '400',
    color: '#080c2d',
    letterSpacing: '2px',
  },
  productContent: {
    fontSize: '1.1em',
    fontWeight: '400',
    color: '#545372',
    paddingLeft: '0px',
    letterSpacing: '0.02rem',
  },
  productContentRed: {
    fontSize: '1.1em',
    fontWeight: '400',
    color: '#f40707',
    paddingLeft: '0px',
    letterSpacing: '0.02rem',
  },
  productContentHeader: {
    fontSize: '1.2em',
    fontWeight: '600',
    color: '#080c2d',
    letterSpacing: '0.03rem',
  },
  imghandy: {
    textAlign: 'center',
    display: 'block',
    marginTop: '20px',
  },
  center: {
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    margin: '0 auto',
  },
  timelineBox: {
    zIndex: 102,
  },
  timelineContent: {
    fontSize: '1.0rem',
    fontWeight: '400',
    textAlign: 'left',
    color: '#080c2d',
  },
  timelineDot: {
    backgroundColor: '#ed292a',
    height: '15px',
    width: '15px',
  },
  boxtimelinecontent: {
    marginTop: '30px',
    marginLeft: '50px',
    padding: '10px 200px 10px 30px',
    '@media (max-width:1280px)': {
      marginTop: '30px',
      marginLeft: '0px',
      padding: '10px 30px 10px 30px',
    },
  },

  timelineItem: {
    minHeight: '40px',
  },
  timelineSeparator: {
    marginTop: '5px',
  },
  timelineConnector1: {
    marginBottom: '-20px !important',
  },
  timelineheadingContentTour: {
    textAlign: 'center',
    fontSize: '2.0rem',
    fontWeight: '600',
    color: '#080c2d',
    marginTop: '2px',
    letterSpacing: '0.1rem',
  },
  imagesBoxImac: {
    textAlign: 'center',
    margin: '0px 100px 0px 100px ',
  },
  timelineDotImac: {
    background: '#db0a0a',
    height: '18px',
    width: '18px',
    marginRight: '10px',
    marginTop: '5px',
    borderBottomStyle: 'solid',
    borderColor: '#efefef',
  },
  timelineDotImacGreen: {
    background: '#263d86',
    height: '14px',
    width: '14px',
    marginRight: '10px',
    marginTop: '7px',
    borderBottomStyle: 'solid',
    borderColor: '#efefef',
  },
  timeLineContentImac: {
    display: 'flex',
    textAlign: 'left',
    fontSize: '1.1rem',
    fontWeight: '400',
    color: '#080c2d',
    margin: '2px 2px 2px 2px ',
    letterSpacing: '0.02rem',
  },
  timeLineContentImacGreen: {
    display: 'flex',
    textAlign: 'left',
    fontSize: '1.0rem',
    fontWeight: '400',
    color: '#545372',
    margin: '2px 2px 2px 2px ',
    letterSpacing: '0.02rem',
  },
  boxContentImac: {
    maxWidth: '1230px',
    margin: '0 auto',
    marginTop: '15px',
    marginBottom: '20px',
    padding: '0px 10px 0px 10px',
  },
  contentBoxImac: {
    textAlign: 'center',
    fontSize: '1.2rem',
    fontWeight: '500',
    color: '#080c2d',
    marginTop: '2px',
    padding: '0px 10px 0px 10px',
    '@media (max-width:1280px)': {
      padding: '0px 10px 0px 10px',
      textAlign: 'left',
    },
  },

  contentBoxTimeLine: {
    textAlign: 'left',
    fontSize: '1.2rem',
    fontWeight: '600',
    color: '#080c2d',
    marginTop: '20px',
  },

  tourcontentboxhoehe: {
    minHeight: '300px',
    marginBottom: '50px',
    padding: '20px',
    '@media (max-width:1280px)': {
      // eslint-disable-line no-useless-computed-key
      minHeight: '300px',
      backgroundColor: '#ebf0f4',
      marginBottom: '50px',
      padding: '20px',
    },
  },
  boxtimeline: {
    top: '100px',
    right: '50px',
    width: '550px',
    '@media (max-width:1280px)': {
      // eslint-disable-line no-useless-computed-key
      display: 'none',
    },
  },
  siteTitleContent: {
    fontSize: '1.3rem',
    fontWeight: '400',
    color: COLOR_WHITE,
    marginLeft: '0px',
    animation: 'textwelle 0.5s',
  },

  siteTitle: {
    fontSize: '2.6rem',
    fontWeight: '600',
    color: COLOR_WHITE,
    marginTop: '50px',
    animation: 'textwelle 0.6s',
  },
  boxImagesHead: {
    minHeight: '300px',
  },
  headImagesTextPosition: {
    display: 'inline-flex',
    maxWidth: '1440px',
    padding: '4em 1em 0em 1em',
  },

  /*---------------------------------*/
}));

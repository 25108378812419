import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { TimelineDot } from '@material-ui/lab';
import ImagesTourHandy from '../imagesSettings/Tour/imagesTourHandy';
import { useProductStyles } from './styles';

const Container30 = () => {
  const { t } = useTranslation();
  const classes = useProductStyles();

  return (
    <>
      <Grid id="Container30" container spacing={3} className={classes.tourcontentboxhoehe}>
        <Grid item lg={12} md={12} xs={12}>
          <Box mt={0}>
            <Typography className={classes.productHeader}>{t('trans.timeLine30')}</Typography>
          </Box>
          <Grid container spacing={1}>
            <Grid item lg={2} md={6} xs={12}>
              <Box className={classes.imghandy}>
                <ImagesTourHandy />
              </Box>
            </Grid>
            <Grid item lg={10} md={6} xs={12}>
              <Box mt={2}>
                <Typography className={classes.productContent}>{t('trans.timeLine30Content1')}</Typography>
              </Box>
              <Box mt={2}>
                <Typography className={classes.productContent}>
                  <Box>{t('trans.timeLine30Content2')}</Box>
                  <Box>{t('trans.timeLine30Content3')}</Box>
                  <Box mt={2} ml={2}>
                    <Typography className={classes.timeLineContentImacGreen}>
                      <TimelineDot className={classes.timelineDotImacGreen} />
                      <b>{t('trans.timeLine30Content4')}</b>
                    </Typography>
                    <Typography className={classes.timeLineContentImacGreen}>
                      <TimelineDot className={classes.timelineDotImacGreen} />
                      <b>{t('trans.timeLine30Content5')}</b>
                    </Typography>
                  </Box>

                  <Box mt={2}>{t('trans.timeLine30Content6')}</Box>
                  <Box>{t('trans.timeLine30Content7')}</Box>
                  <Box mt={2}>{t('trans.timeLine30Content8')}</Box>
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Container30;

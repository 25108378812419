import React from 'react';
import { Button } from '@material-ui/core';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { buttonStyles } from './styles';

const CalculatorButton = () => {
  const { t } = useTranslation();
  const classes = buttonStyles();
  return (
    <>
      <Button className={classes.button} href="/calculator">
        {t('trans.calculatorButton')}
      </Button>
    </>
  );
};

export default CalculatorButton;

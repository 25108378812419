import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { BgImage } from 'gbimage-bridge';
import { Box, Container, Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import stickybits from 'stickybits';
import ContainerTimeLine from './timeLinePage';
import Container5 from './container5';
import Container10 from './container10';
import Container20 from './container20';
import Container25 from './container25';
import Container28 from './container28';
import Container30 from './container30';
import Container40 from './container40';
import Container60 from './container60';

import Container85 from './container85';
import Container90 from './container90';
import Container95 from './container95';
import Container100 from './container100';
import ImacPage from './Imac';
import { useProductStyles } from './styles';

const NewTourPage = () => {
  const { t } = useTranslation();
  const { backgroundImageCallCenter } = useStaticQuery(
    graphql`
      query {
        backgroundImageCallCenter: file(relativePath: { eq: "product3.png" }) {
          childImageSharp {
            gatsbyImageData(width: 2000, quality: 50)
          }
        }
      }
    `,
  );

  React.useEffect(() => {
    stickybits('#timeline', { stickyBitStickyOffset: 106 });
  }, []);

  const pluginImage = getImage(backgroundImageCallCenter);
  const classes = useProductStyles();
  return (
    <>
      <BgImage image={pluginImage} className="masthead2 imagesBlur">
        <Grid container spacing={2} className={classes.headImagesTextPosition}>
          <Grid item lg={9} md={9} xs={12}>
            <Box className={classes.boxImagesHead}>
              <Typography className={classes.siteTitle}>{t('trans.productHeader')}</Typography>

              <Box mt={1.5}>
                <Typography className={classes.siteTitleContent}>{t('trans.productHeaderContent')}</Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </BgImage>

      <Container>
        <Box mt={9}>
          <Typography className={classes.headheadingSite}>{t('trans.product')}</Typography>
          <Typography className={classes.headheadingContent}>{t('trans.productContent')}</Typography>
        </Box>
      </Container>

      <ImacPage />
      <Grid container spacing={4}>
        <Grid item lg={12} md={12} xs={12}>
          <Box mt={1.5}>
            <Typography className={classes.timelineheadingContentTour}>{t('trans.timeLineHead')}</Typography>
          </Box>
        </Grid>
        <Grid item lg={2} md={12} xs={12}>
          <Box id="timeline" className={classes.boxtimeline}>
            <ContainerTimeLine />
          </Box>
        </Grid>

        <Grid item lg={10} md={12} xs={12}>
          <Box className={classes.boxtimelinecontent}>
            <Container5 />
            <Container10 />
            <Container20 />
            <Container25 />
            <Container28 />
            <Container30 />
            <Container40 />
            <Container60 />
            <Container95 />
            <Container85 />
            <Container90 />

            <Container100 />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default NewTourPage;

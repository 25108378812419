import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useProductStyles } from './styles';
import ImagesProductDashboard from '../imagesSettings/Tour/imagesProductDashboard';

const Container5 = () => {
  const { t } = useTranslation();
  const classes = useProductStyles();

  return (
    <>
      <Grid id="Container5" container spacing={3} className={classes.tourcontentboxhoehe}>
        <Grid item lg={12} md={12} xs={12}>
          <Box mt={0}>
            <Typography className={classes.productHeader}>{t('trans.timeLine5')}</Typography>
          </Box>
          <Box mt={0}>
            <Typography className={classes.productContent}>{t('trans.timeLine5Content1')}</Typography>
          </Box>
        </Grid>

        <Grid item lg={6} md={6} xs={12}>
          <ImagesProductDashboard />
        </Grid>
        <Grid item lg={6} md={6} xs={12}>
          <Box mt={0}>
            <Typography className={classes.productContent}>{t('trans.timeLine5Content2')}</Typography>
          </Box>
          <Box mt={2}>
            <Typography className={classes.productContent}>{t('trans.timeLine5Content3')}</Typography>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Container5;

import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { TimelineDot } from '@material-ui/lab';
import { useProductStyles } from './styles';

import ImagesProductInsruction from '../imagesSettings/Tour/imagesProductInstruction';
import ImagesProductionOwnInstrction from '../imagesSettings/Tour/imagesProductionOwnInstrction';

const Container20 = () => {
  const { t } = useTranslation();
  const classes = useProductStyles();

  return (
    <>
      <Grid id="Container20" container spacing={3} className={classes.tourcontentboxhoehe}>
        <Grid item lg={12} md={12} xs={12}>
          <Box mt={0}>
            <Typography className={classes.productHeader}>{t('trans.timeLine20')}</Typography>
          </Box>

          <Box mt={2}>
            <Typography className={classes.productContentHeader}>{t('trans.timeLine20Content1')}</Typography>
          </Box>
          <Box mt={0.5}>
            <Typography className={classes.productContent}>{t('trans.timeLine20Content1-1')}</Typography>
          </Box>
          <Box mt={5}>
            <Grid container spacing={4}>
              <Grid item lg={6} md={6} xs={12}>
                <Box mt={0}>
                  <Typography className={classes.productContent}>{t('trans.timeLine20Content1-3')}</Typography>
                </Box>
                <Box mt={3}>
                  <Typography className={classes.productContent}>{t('trans.timeLine20Content1-4')}</Typography>
                </Box>
                <Box mt={3}>
                  <Typography className={classes.productContent}>{t('trans.timeLine20Content1-5')}</Typography>
                </Box>
              </Grid>
              <Grid item lg={6} md={6} xs={12}>
                <ImagesProductInsruction />
              </Grid>
            </Grid>
          </Box>
          <Box mt={0}>
            <Typography className={classes.productContent}>{t('trans.timeLine20Content1-6')}</Typography>
          </Box>

          <Box mt={2}>
            <Typography className={classes.productContent}>
              <b>{t('trans.timeLine20Content1-8')}</b>
            </Typography>
          </Box>
          <Box ml={2} mt={0.5}>
            <Typography className={classes.timeLineContentImacGreen}>
              <TimelineDot className={classes.timelineDotImacGreen} />
              {t('trans.timeLine20Content1-8a')}
            </Typography>
            <Typography className={classes.timeLineContentImacGreen}>
              <TimelineDot className={classes.timelineDotImacGreen} />
              {t('trans.timeLine20Content1-8b')}
            </Typography>
            <Typography className={classes.timeLineContentImacGreen}>
              <TimelineDot className={classes.timelineDotImacGreen} />
              {t('trans.timeLine20Content1-8c')}
            </Typography>
            <Typography className={classes.timeLineContentImacGreen}>
              <TimelineDot className={classes.timelineDotImacGreen} />
              {t('trans.timeLine20Content1-8d')}
            </Typography>
            <Typography className={classes.timeLineContentImacGreen}>
              <TimelineDot className={classes.timelineDotImacGreen} />
              {t('trans.timeLine20Content1-8e')}
            </Typography>
            <Typography className={classes.timeLineContentImacGreen}>
              <TimelineDot className={classes.timelineDotImacGreen} />
              {t('trans.timeLine20Content1-8f')}
            </Typography>
            <Typography className={classes.timeLineContentImacGreen}>
              <TimelineDot className={classes.timelineDotImacGreen} />
              {t('trans.timeLine20Content1-8g')}
            </Typography>
            <Typography className={classes.timeLineContentImacGreen}>
              <TimelineDot className={classes.timelineDotImacGreen} />
              {t('trans.timeLine20Content1-8h')}
            </Typography>
          </Box>
          <Box mt={0.5}>
            <Typography className={classes.productContent}>{t('trans.timeLine20Content1-9')}</Typography>
          </Box>
          <Box mt={5}>
            <Typography className={classes.productContentHeader}>{t('trans.timeLine20Content2')}</Typography>
          </Box>
          <Box mt={2}>
            <Typography className={classes.productContent}>{t('trans.timeLine20Content2-1')}</Typography>
          </Box>
          <Box mt={3}>
            <Typography className={classes.productContent}>{t('trans.timeLine20Content2-2')}</Typography>
          </Box>
          <Box mt={5}>
            <Typography className={classes.productContentHeader}>{t('trans.timeLine20Content3')}</Typography>
          </Box>
          <Box mt={3}>
            <Grid container spacing={4}>
              <Grid item lg={6} md={6} xs={12}>
                <Box mt={0}>
                  <ImagesProductionOwnInstrction />
                </Box>
              </Grid>
              <Grid item lg={6} md={6} xs={12}>
                <Box mt={0}>
                  <Typography className={classes.productContent}>{t('trans.timeLine20Content3-1')}</Typography>
                </Box>
                <Box mt={2}>
                  <Typography className={classes.productContent}>{t('trans.timeLine20Content3-2')}</Typography>
                </Box>
                <Box mt={2}>
                  <Typography className={classes.productContent}>{t('trans.timeLine20Content3-3')}</Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Container20;

import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { TimelineDot } from '@material-ui/lab';
import { useProductStyles } from './styles';

const Container10 = () => {
  const { t } = useTranslation();
  const classes = useProductStyles();

  return (
    <>
      <Grid id="Container10" container spacing={3} className={classes.tourcontentboxhoehe}>
        <Grid item lg={12} md={12} xs={12}>
          <Box mt={0}>
            <Typography className={classes.productHeader}>{t('trans.timeLine10')}</Typography>
          </Box>

          <Box mt={0}>
            <Typography className={classes.productContent}>
              {t('trans.timeLine10Content1')}
              <b>{t('trans.timeLine10Content1bold')}</b>
              {t('trans.timeLine10Content1-0')}
            </Typography>
          </Box>
          <Box mt={3}>
            <Typography className={classes.productContentHeader}>{t('trans.timeLine10Content1-1')}</Typography>
          </Box>
          <Box mt={0.5}>
            <Typography className={classes.productContent}>{t('trans.timeLine10Content1-2')}</Typography>
          </Box>
          <Box mt={2}>
            <Typography className={classes.productContent}>
              <b>{t('trans.timeLine10Content1-3')}</b>
            </Typography>
          </Box>
          <Box ml={2} mt={0.5}>
            <Typography className={classes.timeLineContentImacGreen}>
              <TimelineDot className={classes.timelineDotImacGreen} />
              {t('trans.timeLine10Content1-4a')}
            </Typography>
            <Typography className={classes.timeLineContentImacGreen}>
              <TimelineDot className={classes.timelineDotImacGreen} />
              {t('trans.timeLine10Content1-4b')}
            </Typography>
            <Typography className={classes.timeLineContentImacGreen}>
              <TimelineDot className={classes.timelineDotImacGreen} />
              {t('trans.timeLine10Content1-4c')}
            </Typography>
            <Typography className={classes.timeLineContentImacGreen}>
              <TimelineDot className={classes.timelineDotImacGreen} />
              {t('trans.timeLine10Content1-4d')}
            </Typography>
            <Typography className={classes.timeLineContentImacGreen}>
              <TimelineDot className={classes.timelineDotImacGreen} />
              {t('trans.timeLine10Content1-4e')}
            </Typography>
            <Typography className={classes.timeLineContentImacGreen}>
              <TimelineDot className={classes.timelineDotImacGreen} />
              {t('trans.timeLine20Content1-8h')}
            </Typography>
          </Box>
          <Box mt={2}>
            <Typography className={classes.productContent}>
              <b>{t('trans.timeLine10Content1-5')}</b>
            </Typography>
          </Box>
          <Box ml={2} mt={0.5}>
            <Typography className={classes.timeLineContentImacGreen}>
              <TimelineDot className={classes.timelineDotImacGreen} />
              {t('trans.timeLine10Content1-6a')}
            </Typography>
            <Typography className={classes.timeLineContentImacGreen}>
              <TimelineDot className={classes.timelineDotImacGreen} />
              {t('trans.timeLine10Content1-6b')}
            </Typography>
            <Typography className={classes.timeLineContentImacGreen}>
              <TimelineDot className={classes.timelineDotImacGreen} />
              {t('trans.timeLine10Content1-6c')}
            </Typography>
            <Typography className={classes.timeLineContentImacGreen}>
              <TimelineDot className={classes.timelineDotImacGreen} />
              {t('trans.timeLine10Content1-6d')}
            </Typography>
            <Typography className={classes.timeLineContentImacGreen}>
              <TimelineDot className={classes.timelineDotImacGreen} />
              {t('trans.timeLine10Content1-6e')}
            </Typography>
            <Typography className={classes.timeLineContentImacGreen}>
              <TimelineDot className={classes.timelineDotImacGreen} />
              {t('trans.timeLine20Content1-8h')}
            </Typography>
          </Box>

          <Box mt={3}>
            <Typography className={classes.productContent}>{t('trans.timeLine10Content1-7')}</Typography>
          </Box>
          <Box mt={1}>
            <Typography className={classes.productContent}>{t('trans.timeLine10Content1-8')}</Typography>
          </Box>
          <Box mt={1}>
            <Typography className={classes.productContent}>{t('trans.timeLine10Content1-9')}</Typography>
          </Box>

          <Box mt={5}>
            <Typography className={classes.productContentHeader}>{t('trans.timeLine10Content2-1')}</Typography>
          </Box>
          <Box mt={0.5}>
            <Typography className={classes.productContent}>{t('trans.timeLine10Content2-2')}</Typography>
          </Box>
          <Box mt={0}>
            <Typography className={classes.productContent}>{t('trans.timeLine10Content2-3')}</Typography>
          </Box>
          <Box mt={0}>
            <Typography className={classes.productContent}>{/* t('trans.timeLine10Content2-4') */}</Typography>
          </Box>
          <Box mt={5}>
            <Typography className={classes.productContentHeader}>{t('trans.timeLine10Content3-1')}</Typography>
          </Box>
          <Box mt={0.5}>
            <Typography className={classes.productContent}>
              {t('trans.timeLine10Content3-2')}
              <b>{t('trans.timeLine10Content3-3')}</b>
              {t('trans.timeLine10Content3-4')}
              <b>{t('trans.timeLine10Content3-5')}</b>
              {t('trans.timeLine10Content3-6')}
            </Typography>
          </Box>
          <Box mt={0}>
            <Typography className={classes.productContent}>{t('trans.timeLine10Content3-7')}</Typography>
          </Box>
          <Box mt={5}>
            <Typography className={classes.productContentHeader}>{t('trans.timeLine10Content4-1')}</Typography>
          </Box>
          <Box mt={0.5}>
            <Typography className={classes.productContent}>{t('trans.timeLine10Content4-2')}</Typography>
          </Box>
          <Box mt={0}>
            <Typography className={classes.productContent}>{t('trans.timeLine10Content4-3')}</Typography>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Container10;

import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { useTranslation, Link } from 'gatsby-plugin-react-i18next';
import styled from 'styled-components';
import { useProductStyles } from './styles';

const Container60 = () => {
  const { t } = useTranslation();
  const classes = useProductStyles();

  const LinkText = styled(Link)`
    display: inline-block;
    z-index: -2;
    text-decoration: none;
    color: #ea5e2a;

    &:hover {
      color: #ea5e2a;
      text-decoration: none;
    }
    &:after {
      opacity: 0;
      text-decoration: none;
    }
    &:hover&:after {
      opacity: 0;
      text-decoration: none;
    }
  `;
  return (
    <>
      <Grid id="Container60" container spacing={3} className={classes.tourcontentboxhoehe}>
        <Grid item lg={12} md={12} xs={12}>
          <Box mt={0}>
            <Typography className={classes.productHeader}>{t('trans.timeLine60')}</Typography>
          </Box>

          <Box mt={2}>
            <Typography className={classes.productContent}>{t('trans.timeLine60Content1')}</Typography>
          </Box>
          <Box mt={0}>
            <Typography className={classes.productContent}>{t('trans.timeLine60Content2')}</Typography>
          </Box>
          <Box mt={0}>
            <Typography className={classes.productContent}>{t('trans.timeLine60Content3')}</Typography>
          </Box>
          <Box mt={2}>
            <Typography className={classes.productContent}>{t('trans.timeLine60Content4')}</Typography>
          </Box>
          <Box mt={2}>
            <Typography className={classes.productContent}>
              {t('trans.timeLine60Content5')}{' '}
              <LinkText to="/prices#pricesplan">
                <span>{t('trans.timeLine60Content6')}</span>
              </LinkText>
              {t('trans.timeLine60Content7')}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Container60;

import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useProductStyles } from './styles';

const Container100 = () => {
  const { t } = useTranslation();
  const classes = useProductStyles();

  return (
    <>
      <Grid id="Container100" container spacing={3} className={classes.tourcontentboxhoehe}>
        <Grid item lg={12} md={12} xs={12}>
          <Box mt={0}>
            <Typography className={classes.productHeader}>{t('trans.timeLine100')}</Typography>
          </Box>

          <Box mt={2}>
            <Typography className={classes.productContent}>{t('trans.timeLine100Content1')}</Typography>
          </Box>
          <Box mt={0}>
            <Typography className={classes.productContent}>{t('trans.timeLine100Content2')}</Typography>
          </Box>
          <Box mt={2}>
            <Typography className={classes.productContent}>{t('trans.timeLine100Content3')}</Typography>
          </Box>
          <Box mt={2}>
            <Typography className={classes.productContent}>{t('trans.timeLine100Content4')}</Typography>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Container100;

import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import ImagesProductionInapphilfe from '../imagesSettings/Product/imagesCompanySruktion copy';
import { useProductStyles } from './styles';

const Container90 = () => {
  const { t } = useTranslation();
  const classes = useProductStyles();

  return (
    <>
      <Grid id="Container90" container spacing={3} className={classes.tourcontentboxhoehe}>
        <Grid item lg={12} md={12} xs={12}>
          <Box mt={0}>
            <Typography className={classes.productHeader}>{t('trans.timeLine90')}</Typography>
          </Box>
          <Grid container spacing={1}>
            <Grid item lg={4} md={6} xs={12}>
              <Box className={classes.imghandy}>
                <ImagesProductionInapphilfe />
              </Box>
            </Grid>
            <Grid item lg={8} md={6} xs={12}>
              <Box mt={2}>
                <Typography className={classes.productContent}>{t('trans.timeLine90Content1')}</Typography>
              </Box>
              <Box mt={2}>
                <Typography className={classes.productContent}>{t('trans.timeLine90Content2')}</Typography>
                <Box mt={2}>
                  <Typography className={classes.productContent}>{t('trans.timeLine90Content3')}</Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Container90;

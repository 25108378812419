import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useProductStyles } from './styles';

const Container25 = () => {
  const { t } = useTranslation();
  const classes = useProductStyles();

  return (
    <>
      <Grid id="Container25" container spacing={3} className={classes.tourcontentboxhoehe}>
        <Grid item lg={12} md={12} xs={12}>
          <Box mt={0}>
            <Typography className={classes.productHeader}>{t('trans.timeLine25')}</Typography>
          </Box>

          <Box mt={2}>
            <Typography className={classes.productContent}>{t('trans.timeLine25Content1')}</Typography>
            <Typography className={classes.productContent}>
              {t('trans.timeLine25Content2')}
              <b>{t('trans.timeLine25Content3')}</b>
              {t('trans.timeLine25Content4')}
            </Typography>
          </Box>
          <Box mt={2}>
            <Typography className={classes.productContent}>{t('trans.timeLine25Content5')}</Typography>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Container25;

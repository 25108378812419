/* eslint-disable import/no-unresolved */
import React from 'react';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import { Box, Typography } from '@material-ui/core';
import { styled } from '@material-ui/styles';
import { useTranslation, Link } from 'gatsby-plugin-react-i18next';
import { useProductStyles } from './styles';

const ItemLink = styled(Link)`
  display: inline-block;
  z-index: -2;
  text-decoration: none;
  color: #c8c9c9;
  &:hover {
    color: #ea5e2a;
    text-decoration: none;
  }
  &:after {
    opacity: 0;
    text-decoration: none;
  }
  &:hover&:after {
    opacity: 0;
    text-decoration: none;
  }
`;

const ContainerTimeLine = () => {
  const { t } = useTranslation();
  const classes = useProductStyles();

  return (
    <>
      <Box className={classes.timelineBox}>
        <Timeline align="right">
          <TimelineItem className={classes.timelineItem}>
            <TimelineSeparator className={classes.timelineSeparator}>
              <TimelineDot className={classes.timelineDot} />
              <TimelineConnector className={classes.timelineConnector1} />
            </TimelineSeparator>
            <TimelineContent>
              <ItemLink to="/product/#Container5">
                <Typography className={classes.timelineContent}>{t('trans.timeLine5')}</Typography>
              </ItemLink>
            </TimelineContent>
          </TimelineItem>

          <TimelineItem className={classes.timelineItem}>
            <TimelineSeparator className={classes.timelineSeparator}>
              <TimelineDot className={classes.timelineDot} />
              <TimelineConnector className={classes.timelineConnector1} />
            </TimelineSeparator>
            <TimelineContent>
              <ItemLink to="/product/#Container10">
                <Typography className={classes.timelineContent}>{t('trans.timeLine10')}</Typography>
              </ItemLink>
            </TimelineContent>
          </TimelineItem>

          <TimelineItem className={classes.timelineItem}>
            <TimelineSeparator className={classes.timelineSeparator}>
              <TimelineDot className={classes.timelineDot} />
              <TimelineConnector className={classes.timelineConnector1} />
            </TimelineSeparator>
            <TimelineContent>
              <ItemLink to="/product/#Container20">
                <Typography className={classes.timelineContent}>{t('trans.timeLine20')}</Typography>
              </ItemLink>
            </TimelineContent>
          </TimelineItem>

          <TimelineItem className={classes.timelineItem}>
            <TimelineSeparator className={classes.timelineSeparator}>
              <TimelineDot className={classes.timelineDot} />
              <TimelineConnector className={classes.timelineConnector1} />
            </TimelineSeparator>
            <TimelineContent>
              <ItemLink to="/product/#Container25">
                <Typography className={classes.timelineContent}>{t('trans.timeLine25')}</Typography>
              </ItemLink>
            </TimelineContent>
          </TimelineItem>

          <TimelineItem className={classes.timelineItem}>
            <TimelineSeparator className={classes.timelineSeparator}>
              <TimelineDot className={classes.timelineDot} />
              <TimelineConnector className={classes.timelineConnector1} />
            </TimelineSeparator>
            <TimelineContent>
              <ItemLink to="/product/#Container28">
                <Typography className={classes.timelineContent}>{t('trans.timeLine28')}</Typography>
              </ItemLink>
            </TimelineContent>
          </TimelineItem>

          <TimelineItem className={classes.timelineItem}>
            <TimelineSeparator className={classes.timelineSeparator}>
              <TimelineDot className={classes.timelineDot} />
              <TimelineConnector className={classes.timelineConnector1} />
            </TimelineSeparator>
            <TimelineContent>
              <ItemLink to="/product/#Container30">
                <Typography className={classes.timelineContent}>{t('trans.timeLine30')}</Typography>
              </ItemLink>
            </TimelineContent>
          </TimelineItem>

          <TimelineItem className={classes.timelineItem}>
            <TimelineSeparator className={classes.timelineSeparator}>
              <TimelineDot className={classes.timelineDot} />
              <TimelineConnector className={classes.timelineConnector1} />
            </TimelineSeparator>
            <TimelineContent>
              <ItemLink to="/product/#Container40">
                <Typography className={classes.timelineContent}>{t('trans.timeLine40')}</Typography>
              </ItemLink>
            </TimelineContent>
          </TimelineItem>

          <TimelineItem className={classes.timelineItem}>
            <TimelineSeparator className={classes.timelineSeparator}>
              <TimelineDot className={classes.timelineDot} />
              <TimelineConnector className={classes.timelineConnector1} />
            </TimelineSeparator>
            <TimelineContent>
              <ItemLink to="/product/#Container60">
                <Typography className={classes.timelineContent}>{t('trans.timeLine60')}</Typography>
              </ItemLink>
            </TimelineContent>
          </TimelineItem>

          <TimelineItem className={classes.timelineItem}>
            <TimelineSeparator className={classes.timelineSeparator}>
              <TimelineDot className={classes.timelineDot} />
              <TimelineConnector className={classes.timelineConnector1} />
            </TimelineSeparator>
            <TimelineContent>
              <ItemLink to="/product/#Container95">
                <Typography className={classes.timelineContent}>{t('trans.timeLine95')}</Typography>
              </ItemLink>
            </TimelineContent>
          </TimelineItem>

          <TimelineItem className={classes.timelineItem}>
            <TimelineSeparator className={classes.timelineSeparator}>
              <TimelineDot className={classes.timelineDot} />
              <TimelineConnector className={classes.timelineConnector1} />
            </TimelineSeparator>
            <TimelineContent>
              <ItemLink to="/product/#Container85">
                <Typography className={classes.timelineContent}>{t('trans.timeLine85')}</Typography>
              </ItemLink>
            </TimelineContent>
          </TimelineItem>

          <TimelineItem className={classes.timelineItem}>
            <TimelineSeparator className={classes.timelineSeparator}>
              <TimelineDot className={classes.timelineDot} />
              <TimelineConnector className={classes.timelineConnector1} />
            </TimelineSeparator>
            <TimelineContent>
              <ItemLink to="/product/#Container90">
                <Typography className={classes.timelineContent}>{t('trans.timeLine90')}</Typography>
              </ItemLink>
            </TimelineContent>
          </TimelineItem>

          <TimelineItem className={classes.timelineItem}>
            <TimelineSeparator className={classes.timelineSeparator}>
              <TimelineDot className={classes.timelineDot} />
            </TimelineSeparator>
            <TimelineContent>
              <ItemLink to="/product/#Container100">
                <Typography className={classes.timelineContent}>{t('trans.timeLine100')}</Typography>
              </ItemLink>
            </TimelineContent>
          </TimelineItem>
        </Timeline>
      </Box>
    </>
  );
};
export default ContainerTimeLine;
